<template>
	<section class="columns is-multiline is-mobile">
		<Title />
		<div v-if="visible" class="page__actions column">
			<slot></slot>
		</div>
		<Weather :bordered="bordered" />
		<Balance />
		<button id="button-theme" class="page__button page__button--mode" @click="mode()">
			<b-icon :icon="isDark ? 'weather-night' : 'white-balance-sunny'"></b-icon>
		</button>
		<Notifications />
	</section>
</template>

<script>
import Title from '@/components/Title'
import Weather from '@/components/Weather'
import Balance from '@/components/Balance'
import Notifications from '@/components/Notifications'
import { mapGetters, mapMutations } from 'vuex'
import Api from '@/services/api'

export default {
	name: 'Header',
	components: {
		Title,
		Weather,
		Balance,
		Notifications
	},
	props: {
		bordered: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			visible: true
		}
	},
	computed: {
		...mapGetters('user', [
			'isDark']
		),
		name() {
			return this.$route.name
		}
	},
	mounted() {
		const slot = this.$slots.default
		this.visible = slot && slot.length > 0 ? true : false
	},
	methods: {
		...mapMutations('user', [
			'setTheme'
		]),
		mode() {
			let newTheme = this.isDark ? 'light' : 'dark'

			this.setTheme(newTheme)
			let user = JSON.parse(localStorage.getItem('@stup:user'))
			user.theme = newTheme
			localStorage.setItem('@stup:user', JSON.stringify(user))

			Api.post('users/theme', { theme: newTheme })
		}
	}
}
</script>
