<template>
	<div class="header__balance" v-if="isDesktop">
		<!-- prettier-ignore -->
		<svg xmlns="http://www.w3.org/2000/svg" width="23.985" height="21.492" viewBox="0 0 23.985 21.492">
			<g transform="translate(-80.5 -125.4)">
				<path d="M92.1,136.081v.175a.336.336,0,0,0,0,.053.1.1,0,0,0,0,.028.1.1,0,0,0,0,.031,4.286,4.286,0,0,0,1.312,2.849,4.38,4.38,0,0,0,3.054,1.237h6.851v4.386a2.056,2.056,0,0,1-2.054,2.054H82.546a2.047,2.047,0,0,1-2.046-2.046v-17.4a2.047,2.047,0,0,1,2.046-2.046h18.73a2.056,2.056,0,0,1,2.054,2.054v4.383h-6.9a4.476,4.476,0,0,0-1.473.245h0a4.184,4.184,0,0,0-1.581.989,4.391,4.391,0,0,0-.645.817c-.008.014-.017.028-.025.044-.028.044-.053.089-.078.133l-.025.044c-.017.031-.031.061-.047.092l-.042.086c-.053.108-.1.222-.142.334l-.017.047c-.053.147-.1.3-.136.453,0,.014-.006.025-.008.036s-.008.039-.014.058l-.008.042c-.006.022-.008.044-.014.067s0,.022-.006.033l-.008.05-.008.05-.008.05a.306.306,0,0,0-.006.05c-.008.072-.017.145-.022.217l-.008.136v.011c0,.033,0,.067,0,.1A.5.5,0,0,1,92.1,136.081Z" transform="translate(0 0)" fill="#000000" opacity="0.8" />
				<path d="M548.66,397h-7.568a3.171,3.171,0,0,0-3.213,3.235,3.262,3.262,0,0,0,3.266,3.157h7.4a.609.609,0,0,0,.609-.609v-5.292A.491.491,0,0,0,548.66,397Zm-7.587,4.439a1.276,1.276,0,1,1,1.276-1.276A1.262,1.262,0,0,1,541.073,401.439Z" transform="translate(-444.667 -264.051)" fill="#000000" opacity="0.3" />
			</g>
		</svg>
		<span>
			<!-- prettier-ignore -->
			<p><strong>{{ totalTokens }}</strong></p>
			<p>$ESY</p>
		</span>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { responsive } from '@/mixins/responsive'

export default {
	name: 'Balance',
	mixins: [responsive],
	computed: {
		...mapState('user', [
			'totalTokens'
		])
	}
}
</script>
